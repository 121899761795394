<template>
  <div class="about">
    <div class="banner">
      <img src="@/assets/pic_banner5.png" alt="">
    </div>
    <div class="introduction">
      <div class="text">
        公司介绍
      </div>
      <div class="sub-text">
<!--        公司成立于2020年，致力于为进出口贸易客户提供一站式解决方案：注册登记、报关出口、资金购/结汇、出口退税、核定征收。合作国内外一流的银行为资金提供足够的安全保障。公司拥有10年以上的行业专家团队，具有非常丰富的行业经验和创新能力。-->
        <!--        数美信息科技（品牌名：数字美学）成立于2020年6月6日。数美信息科技主要致力于解决全球跨境资金流通的效能提升问题，如全球资金的跨境清算、外币兑换、全球资金授信、全球资金分发等综合服务解决方案。系统平台基于区块链底层技术搭建，是数字货币及人民币国际化清算生态布局的先头兵。-->
      </div>
      <div class="sub-text">
        数美信息科技目前的产品及解决方案主要为进出口贸易客户提供一站式解决方案：自贸区园区企业注册登记、报关出口、出口退税、核定征收。合作国内外一流的银行为资金提供足够的安全保障。公司拥有10年以上的跨境行业专家团队，具有非常丰富的行业经验和创新能力。致力于构建阳光化全球资金跨境及国际贸易新生态。
      </div>
    </div>
<!--    <div class="target">-->
<!--      致力于构建阳光化全球资金跨境及国际贸易新生态-->
<!--    </div>-->
    <div class="partners">
      <div class="text">
        合作伙伴
      </div>
      <div class="partners-list">
        <div class="partner-item">
          <img src="@/assets/logo1.png" alt="">
          <div class="partner-text">
            工商银行
          </div>
        </div>
        <div class="partner-item">
          <img src="@/assets/logo2.png" alt="">
          <div class="partner-text">
            中信银行
          </div>
        </div>
        <div class="partner-item">
          <img src="@/assets/logo3.png" alt="">
          <div class="partner-text">
            微信支付
          </div>
        </div>
        <div class="partner-item">
          <img src="@/assets/logo4.png" alt="">
          <div class="partner-text">
            支付宝
          </div>
        </div>
      </div>
    </div>
    <div class="location">
      <div class="left-container">
        <div class="title">
          深圳市数美信息科技有限公司
        </div>
        <div class="phone">
          <img class="icon" src="@/assets/icon_tel.svg" alt="">
          联系我们：service@shumeit.com
        </div>
        <div class="position">
          <img class="icon" src="@/assets/icon_position.svg" alt="">
          公司地址：深圳市南山区粤海街道海珠社区兰芷二路66号阳光保险大厦2305-08
        </div>
      </div>
      <div class="right-container">
        <baidu-map id="map" class="map" @ready="mapReady" :scroll-wheel-zoom="true">
          <!-- bm-marker 就是标注点 定位在point的经纬度上 跳动的动画 -->
          <bm-marker :position="point" animation="BMAP_ANIMATION_BOUNCE">
          </bm-marker>
        </baidu-map>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Industry',
    components: {},
    data() {
      return {
        point: '113.950033,22.525591'
      }
    },
    methods: {
      mapReady({BMap, map}) {
        this.point = new BMap.Point(113.950033, 22.525591);
        map.centerAndZoom(this.point, 16);
      },
    },
    mounted() {
    }
  }
</script>

<style lang="scss" scoped>
  .about {
    width: 100%;
  }

  .banner {
    position: relative;
    background: #FCFCFC;

    img {
      width: 100%;
    }
  }

  .introduction {
    padding: 90px 260px;
    background: #FCFCFC;
    text-align: center;

    .text {
      margin-bottom: 20px;
      font-size: 28px;
      color: #333333;
      line-height: 36px;
    }

    .sub-text {
      font-size: 16px;
      color: #666666;
      line-height: 32px;
      text-align: start;
      text-indent: 40px;
    }
  }

  .target {
    display: flex;
    height: 300px;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    color: #333333;
    line-height: 54px;
  }

  .partners {
    padding: 90px 260px 100px;
    background: #FCFCFC;
    text-align: center;

    .text {
      margin-bottom: 80px;
      font-size: 28px;
      color: #333333;
      line-height: 36px;
    }

    .partners-list {
      display: flex;
      justify-content: space-between;

      .partner-text {
        margin-top: 8px;
        font-size: 20px;
        color: #333333;
        text-align: center;
        line-height: 36px;
      }
    }
  }

  .location {
    display: flex;
    justify-content: space-between;
    padding: 100px 260px;

    .title {
      margin-bottom: 20px;
      font-size: 20px;
      color: #333333;
      line-height: 48px;
    }

    .phone, .position {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #666666;
      text-align: justify;
      line-height: 22px;

      .icon {
        width: 60px;
        margin-right: 8px;
      }
    }

    .map {
      width: 700px;
      height: 300px;
      margin-left: 70px;
    }
  }

  @media screen and (max-width: 1440px) {
    .map {
      width: 500px !important;
    }
  }
</style>
