<template>
  <div class="product">
    <div class="banner">
      <img src="@/assets/pic_banner3.png" alt="">
    </div>
    <div class="product-item">
      <div class="product-img">
        <img src="@/assets/pic_4.png" alt="">
      </div>
      <div class="product-outer-box right">
        <div class="product-inner-box first">
          <div class="product-title">海关申报</div>
          <div class="product-text">
            <p>
              数美信息系统直接连接全国多个电子口岸，‌为客户提供覆盖多口岸、‌多场景的海关申报一体化解决方案。‌
            </p>
            <div>
              <br/>产品优势：
              <ul>
                <li>1、实现与全国多个电子口岸的系统直连。</li>
                <li>2、支持多种监管方式申报，‌包括0110、‌9610、‌9710、‌9810、1039</li>
                <li>3、系统设计简洁易懂，‌操作便捷。</li>
                <li>4、助力客户高效实现通关流程。‌</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="product-item">
      <div class="product-outer-box">
        <div class="product-inner-box second">
          <div class="product-title">税务核定征收</div>
          <div class="product-text">
            <p>
              根据国家税务总局公告2019年第36号规定，在指定的跨境电子商户综合试验区内完成相关注册和备案，并通过当地海关进行电子商户出口申报手续，对于采购货物未取得的进项发票，在海外销售回款时能够按照4%的应税所得率进行阳光缴税。
            </p>
            <div>
              <br/>产品优势：
              <ul>
                <li>1、能够提供一站式的综试区内的企业注册和备案。</li>
                <li>2、提供全线上化的报关功能 。</li>
                <li>3、依托银行进行合规收汇安全可靠。</li>
                <li>4、帮助客户按照应税所得率 4% * 企业所得税25% = 1%的税赋进行缴税。</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="product-img">
        <img src="@/assets/pic_5.png" alt="">
      </div>
    </div>

    <div class="product-item">
      <div class="product-img">
        <img src="@/assets/pic_7.png" alt="">
      </div>
      <div class="product-outer-box">
        <div class="product-inner-box third">
          <div class="product-title">9610报关退税</div>
          <div class="product-text">
            <p>
              客户以跨境电商零售9610方式进行海关申报，在海外销售的资金通过银行进行对公账户后，能够合法合规进行申报退税增加企业利润。
            </p>
            <div>
              <br/>产品优势：
              <ul>
                <li>1、小包货物能够阳光报关出口并获得报关单。</li>
                <li>2、海外销售资金能够阳光申报入境。</li>
                <li>3、资金能够合法合规进入对公账户申请退税。</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="product-item">
      <div class="product-outer-box">
        <div class="product-inner-box third">
          <div class="product-title">AI智能运营</div>
          <div class="product-text">
            <p>
              以客户ERP经营数据为依据，结合行业大数据为参考，根据客户具体的销售品类、营销计划量身订制营销方案，在营销方案投产时，能够根据实时效果反馈结合大数据的智能分析，提供营销策略动态调整的决策方案。
            </p>
            <div>
              <br/>产品优势：
              <ul>
                <li>1、深度分析客户的行业和品类量身订制营销方案。</li>
                <li>2、基于大数据依托智能AI分析动态调整营销策略。</li>
                <li>3、能够帮助客户真正打造品类销售标杆。</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="product-img">
        <img src="@/assets/pic_8.png" alt="">
      </div>
    </div>

    <!-- 暂不展示 -->
    <template v-if="false">
      <div class="product-item">
        <div class="product-outer-box">
          <div class="product-inner-box fourth">
            <div class="product-title">国际付款</div>
            <div class="product-text">
              <div>
                <ul>
                  <li>1、境内付款</li>
                  <li>2、境内跨境付款</li>
                  <li>3、境外本地付款</li>
                  <li>4、境外跨境付款</li>
                </ul>
              </div>
              <div>
                优势：<br>
                采用智能路由算法寻找时效最快成本最低的分发网络通道
                <ul>
                  <li>1、全球化：能够覆盖全球100+个国家的资金分发</li>
                  <li>2、时效快：通过智能路由算法提供时效最快的分发路径</li>
                  <li>3、成本低：通过全球各地的本地分发网络，极大减少中间行费用</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="product-img">
          <img src="@/assets/pic_7.png" alt="">
        </div>
      </div>
      <div class="product-item">
        <div class="product-img">
          <img src="@/assets/pic_8.png" alt="">
        </div>
        <div class="product-outer-box">
          <div class="product-inner-box fifth">
            <div class="product-title">垫资授信</div>
            <div class="product-text">
              <div>
                <ul>
                  <li>1、中国境内授信</li>
                  <li>2、中国境外授信</li>
                </ul>
              </div>
              <div>
                优势：
                <ul>
                  <li>1、根据客户实际需求提供垫资和短期授信加快商户业务资金周转</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

export default {
  name: 'Product',
}
</script>

<style lang="scss" scoped>
.product {
  width: 100%;
}

.banner {
  position: relative;
  background: #FCFCFC;

  img {
    width: 100%;
  }
}

.product-item {
  display: flex;
  margin-top: 100px;
  margin-bottom: 200px;
  padding: 0 260px;

  &:last-of-type {
    margin-bottom: 100px;
  }

  .product-img {
    width: 500px;
  }

  .product-outer-box {
    flex: 1;
    background: #fff;
    padding: 40px 0;

    .product-inner-box {
      height: 420px;
      box-sizing: border-box;
      padding: 64px 80px;
      background: #FAFAFA;

      &.first {
        padding: 30px 80px 0;
      }

      &.second, &.fourth {
        padding: 20px 80px 0;
      }

      &.third {
        padding: 50px 80px 0;
      }

      &.fifth {
        padding: 106px 80px 0;
      }

      .product-title {
        margin-bottom: 20px;
        font-size: 28px;
        color: #333333;
        line-height: 48px;
      }

      .product-text {
        font-size: 16px;
        color: #666666;
        line-height: 28px;

        > div {
          margin-bottom: 40px;

          & + div {
            margin: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .product-inner-box {
    &.first {
      padding: 30px 50px 0 !important;
    }

    &.second, &.fourth {
      padding: 24px 50px 0 !important;
    }

    &.third {
      padding: 12px 50px 0 !important;
    }

    &.fifth {
      padding: 106px 50px 0 !important;
    }
  }
}
</style>
