<template>
  <div class="home">
    <div class="banner">
      <img src="@/assets/pic_banner1.png" alt="">
      <p class="banner-system"><span>全球跨境贸易数字化系统</span></p>
      <div class="banner-btn-contain">
        <button class="banner-btn">
          了解更多
        </button>
      </div>
    </div>
    <div class="introduction">
      <div class="text">
        数美信息科技呈现数字之美，致力于成为全球领先的跨境贸易及金融的数字化科技企业
      </div>
      <div class="sub-text">
        数字美学赋能全球跨境贸易企业，为跨境贸易企业提供全智能数字化系统。围绕跨境贸易企业的“关、税、汇、退、运”提供一体化合规解决方案。能够帮助企业真正做到阳光化、简易化、低成本，同时能够为电商出口贸易类企业提供完成的“第三方电商平台”的智能化运营及决策系统功能。数字美学在帮助企业达到降本增效的目的同时，还能够提供专业的决策方案帮助客户提升交易额。
      </div>
<!--      <div class="sub-text">-->
<!--        数字美学在帮助跨境企业提供合法、合规的专业业务指导和系统服务的同时，帮助企业达到降本增效的目的。-->
<!--      </div>-->
    </div>
    <div class="hu-container">
      <img src="@/assets/hu.png" alt="">
    </div>
    <div class="function-item">
      <div class="function-img">
        <img src="@/assets/pic_1.png" alt="">
      </div>
      <div class="function-box right first">
        <div class="function-title">智能报关</div>
        <div class="function-text">
          <p>
            数美信息自主研发的报关系统，‌凭借其多年行业经验的积累与深厚的技术沉淀，‌成功实现了与全国不同地区电子口岸的直连，同时支持多种不同的监管方式申报。为客户搭建起一条高效、‌便捷的海关申报通道，极大地简化了进出口货物的清关手续。‌帮助企业能够以更加高效的方式完成通关流程，‌提升了企业的运营效率，‌助力企业在全球外贸市场环境中更具竞争力。
          </p>
        </div>
      </div>
    </div>
    <div class="function-item">
      <div class="function-box left second">
        <!-- 中央账本 -->
        <div class="function-title second">阳光税务</div>
        <div class="function-text">
          <p>
            针对出口电商企业无采购进项发票且满足跨境综试区的条件的电商客户，我们能帮助客户提供合法合规的阳光化出口报关、资金申报入境、以及按照入账金额进行4%的应税率进行企业所得税的缴税，实际缴税额为：收入*4%*25%（一般企业缴纳25%的企业所得税），即按照入账金额的1%进行企业所得税缴纳。
            <br/>对于持有采购（进项）发票的客户，我们能提供9610的阳关报关出口、提供供应商货款的委托付款等系统级服务，境外销售货款汇款后我们能帮助客户完成退税。
          </p>
          <!--          <p>-->
          <!--            多币种账户：<br>-->
          <!--            能够满足商户的业务对同一资金账户的要求，还可以满足不同币种的记账。真正为商户实现一个账本管理企业全球资金。-->
          <!--          </p>-->
          <!--          <p>-->
          <!--            区块链分布式账本：<br>-->
          <!--            底层采用区块链分布式账本技术，交易数据不易被篡改，并且可追踪溯源。-->
          <!--          </p>-->
          <!--          <p>-->
          <!--            业务合规：<br>-->
          <!--            在系统业务设计上创新采用交易前置机，校验每笔交易订单数据真实性，同时结合底层区块链技术，还原每笔数据的真实完整交易路径，确保业务开展更加合规。-->
          <!--          </p>-->
        </div>
      </div>
      <div class="function-img">
        <img src="@/assets/pic_2.png" alt="">
      </div>
    </div>
    <div class="function-item">
      <div class="function-img">
        <img src="@/assets/pic_3.png" alt="">
      </div>
      <div class="function-box right">
        <!-- 算法推荐 -->
        <div class="function-title">AI智能运营</div>
        <div class="function-text">
          <p>
            为出口电商客户提供完整的运营投入成本智能决策、广告投放方案及智能决策、财务智能预测及分析等全套智能优化决策方系统。
          </p>
          <p>
            以商户的ERP数据为依据，成功大卖的数据，行业大数据作为参考，围绕消费者画像、销售品类、建立不同的营销场景，结合商户量身订制整个营销方案。
            <br/>通过BI数据看板，实时监控营销活动中每个节点的目标达成情况，通过大数据智能分析，提供需要实时调整的营销决策建议，让商户实现营销效果最大化。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
}
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
}

.banner {
  position: relative;
  background: #FCFCFC;

  img {
    width: 100%;
  }

  .banner-system {
    color: #fff;
    font-size: 54px;
    letter-spacing: 21px;
    font-weight: 900;
    position: absolute;
    bottom: calc(44%);
    left: 22px;
    text-align: center;
    align-items: center;
    width: 100%;
    justify-content: center;
  }

  .banner-btn-contain {
    display: flex;
    justify-content: space-between;
    height: 0;
  }

  .banner-btn {
    position: relative;
    width: 240px;
    height: 60px;
    left: calc(43.6%);
    bottom: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #52BF63;
    color: #fff;
  }
}

.introduction {
  padding: 80px 260px 110px;
  background: #FCFCFC;
  text-align: center;

  .text {
    margin-bottom: 20px;
    font-size: 28px;
    color: #333333;
    line-height: 36px;
  }

  .sub-text {
    font-size: 18px;
    color: #666666;
    line-height: 30px;
    text-align: start;
    text-indent: 40px;
  }
}

.hu-container {
  height: 100px;
  background: #FCFCFC url("../assets/hu.png");
}

.function-item {
  display: flex;
  margin-bottom: 80px;
  padding: 0 260px;

  &:last-of-type {
    margin-bottom: 100px;
  }

  .function-img {
    width: 500px;

    &:hover {
      transform: scale(1.1);
      transition-duration: 0.5s;
    }
  }

  .function-box {
    flex: 1;
    padding-top: 60px;

    &.first {
      position: relative;
      bottom: 12px;
    }

    &.second {
      position: relative;
      bottom: 28px;
    }

    &.left {
      margin-right: 100px;
    }

    &.right {
      margin-left: 100px;
    }

    .function-title {
      margin-bottom: 20px;
      font-size: 28px;
      color: #333333;
      line-height: 48px;

    }

    .function-text {
      font-size: 16px;
      color: #666;
      line-height: 30px;

      p {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
