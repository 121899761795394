<template>
  <div id="app">
    <Header @needLogin="needLogin" id="header"></Header>
    <router-view/>
    <Footer></Footer>
<!--    <div  v-bind:class="{vague:dialogVisible}">-->
<!--      -->
<!--    </div>-->
<!--    <el-dialog class="dialog" :visible.sync="dialogVisible" :show-close="false" :close-on-click-modal="false" width="500px">-->
<!--      <div class="welcome">欢迎登录~</div>-->
<!--      <div class="company-name">深圳市数美信息科技有限公司</div>-->
<!--      <el-form>-->
<!--        <el-form-item>-->
<!--          <el-input v-model="form.loginName" placeholder="请输入用户名或手机号"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item>-->
<!--          <el-input v-model="form.password" type="password" placeholder="请输入密码"></el-input>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
<!--      <el-button class="dialog-btn" @click="login">登录</el-button>-->
<!--    </el-dialog>-->
  </div>
</template>

<script>
  import { setToken, removeToken } from "./js/loginToken";
  import request from '@/js/request';
  const SHA256 = require('crypto-js/sha256');

  export default {
    data() {
      return {
        dialogVisible: false,
        form: {
          loginName: '',
          password: ''
        }
      }
    },
    methods: {
      login() {
        request({
          url: '/user/login',
          method: 'post',
          data: {
            loginName: this.form.loginName,
            password: SHA256(this.form.password).toString()
          }
        }).then(res => {
          if(res.code == 20000) {
            setToken(res.data.token);
            this.form = {
              loginName: '',
              password: ''
            }
            this.dialogVisible = false;
          }
        });
      },
      needLogin() {
        removeToken();
        console.log(111);
        this.dialogVisible = true;
      }
    },
    mounted() {
      // if(!getToken()) {
      //   this.dialogVisible = true;
      // }


      var nav = document.getElementById('header');
      window.onscroll=function(){
        var panduan =document.documentElement.scrollTop ||document.body.scrollTop;
        if(panduan>=475){
          nav.classList.add('bg-class');
        }else{
          nav.classList.remove('bg-class');
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  #app {
    min-width: 1680px;
  }
  .vague *:not(.el-dialog__wrapper){
    -webkit-filter: blur(6px);
    overflow: hidden;
  }
  .dialog {
    .welcome {
      margin-bottom: 20px;
      font-size: 14px;
      color: #ccc;
    }
    .company-name {
      margin-bottom: 40px;
      font-size: 20px;
      font-weight: bold;
    }
    .dialog-btn {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      background: #52BF63;
      color: #fff;
    }
  }
</style>
