<template>
  <div class="header">
    <div class="left-container">
      <div class="logo" @click="$router.push('/home')">
        <img src="@/assets/logo-t.png" alt="">
      </div>
      <div class="nav">
        <span :class="activeTab == '/home' ? 'active' : ''" @click.stop="linkTo('/home')">
          首页
        </span>
        <span :class="activeTab == '/product' ? 'active' : ''"  @click.stop="linkTo('/product')">
          产品服务
        </span>
        <span :class="activeTab == '/industry' ? 'active' : ''" @click.stop="linkTo('/industry')">
          赋能行业
        </span>
        <span :class="activeTab == '/about' ? 'active' : ''" @click.stop="linkTo('/about')">
          关于我们
        </span>
      </div>
    </div>
    <div class="right-container">
      <div class="btn-box">
        <button class="login-btn" @click.stop="openUrl('https://cb.shumeit.com/')">登录</button>
        <button class="register-btn" @click.stop="openUrl('https://cb.shumeit.com/register')">立即注册</button>
      </div>
      <div class="language">
        简体中文
      </div>
    </div>
  </div>
</template>

<script>
  // import request from '@/js/request.js';
  export default {
    name: "Header",
    computed: {
      activeTab() {
        return this.$route.path;
      }
    },
    methods: {
      linkTo(url) {
        this.$router.push(url);
        // request({
        //   url: url,
        //   method: 'get'
        // }).then(res => {
        //   if(res.code != 20000) {
        //     this.$emit('needLogin');
        //   } else {
        //     this.$router.push(url);
        //   }
        // })
      },
      openUrl(url) {
        window.open(url, '_target');
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    position: fixed;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    min-width: 1500px;
    /*max-width: 1920px;*/
    height: 76px;
    background: transparent;
    color: #fff;
    z-index: 99;
    &.bg-class {
      background: #111114;
    }
    .left-container {
      display: flex;
      .logo {
        width: 223px;
        height: 100%;
        margin: 0 40px;
        cursor: pointer;
      }
      .nav {
        display: flex;
        span {
          position: relative;
          display: flex;
          align-items: center;
          margin: 0 30px;
          font-size: 18px;
          cursor: pointer;
          &:hover, &.active {
            &::before {
              position: absolute;
              top: 0px;
              left: -50%;
              display: inline-block;
              content: '';
              width: 100%;
              height: 4px;
              background: #52BF63;
              transform: translateX(50%);
            }
          }
        }
      }
    }
    .right-container {
      display: flex;
      margin-right: 73px;
      .btn-box {
        margin-right: 50px;
        button {
          height: 40px;
          padding: 10px 30px;
          color: #fff;
          background: transparent;
          &+ button {
            margin-left: 20px;
          }
          &.login-btn {
            background: #52BF63;
            cursor: pointer;
          }
          &.register-btn {
            border: 1px solid #fff;
            cursor: pointer;
          }
        }
      }
      .language {
        display: flex;
        align-items: center;
        font-size: 14px;
      }
    }

  }
</style>
