<template>
  <div class="footer">
    <div class="footer-main">
      <div class="logo">
        <img src="@/assets/logo-t.png" alt="">
      </div>
      <div class="nav">
        <div @click.stop="linkTo('/product')">
          产品服务
        </div>
        <div @click.stop="linkTo('/industry')">
          行业覆盖
        </div>
        <div @click.stop="linkTo('/about')">
          关于我们
        </div>
      </div>
      <div class="chat-box">
        <div>
          <span class="chat-title">联系我们：</span>
          <span>service@shumeit.com</span>
        </div>
        <div>
          <span class="chat-title">地址：</span>
          <span>深圳市南山区粤海街道海珠社区兰芷二路66号阳光保险大厦2305-08</span>
        </div>
      </div>
    </div>
    <div class="copyright">
      <p class="icpBox">
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502006064">
          <img src="@/assets/images/icpbgs.png" class="footer-bottom-icpbg"/>
          <span style="color: #707072"> 粤公网安备44030502006064号</span>
        </a>
        &nbsp;|&nbsp;
        <a target="_blank" href="https://beian.miit.gov.cn/">
          <!--<img src="../../assets/images/icpbgs.png" class="footer-bottom-icpbg"/>&nbsp;-->
          <span style="color: #707072">粤ICP备20072242号-1</span>
        </a>
        &nbsp;|&nbsp;
        <span>{{ Copyright }}</span>
      </p>
      <!--      版权所有©2021 深圳市数美信息科技有限公司。保留所有权利-->
    </div>
  </div>
</template>

<script>
// import request from '@/js/request.js';

export default {
  name: "Footer",
  data() {
    return {
      Copyright: 'Copyright © 数美' + (new Date().getFullYear()) + '版权所有',
    }
  },
  methods: {
    linkTo(url) {
      this.$router.push(url);
      document.documentElement.scrollTop = 0;
      // request({
      //   url: url,
      //   method: 'get'
      // }).then(res => {
      //   if(res.code != 20000) {
      //     this.$emit('needLogin');
      //   } else {
      //     this.$router.push(url);
      //   }
      // })
    },
  }
}
</script>

<style lang="scss" scoped>
.footer {
  position: relative;
}

.footer-main {
  font-size: 14px;
  display: flex;
  width: 100%;
  height: 320px;
  box-sizing: border-box;
  padding: 60px 270px;
  background: url("../assets/bottom.png");
  letter-spacing: 1px;

  .logo {
    width: 160px;
    margin-right: 170px;
  }

  .nav {
    margin-right: 190px;
    padding-top: 16px;
    cursor: pointer;

    div {
      min-width: 60px;
      margin-bottom: 20px;
      color: #fff;
    }
  }

  .chat-box {
    padding-top: 16px;
    color: #fff;

    div {
      margin-bottom: 25px;

      .chat-title {
        display: inline-block;
        width: 80px;
        color: #707072;
      }
    }
  }
}

.copyright {
  position: absolute;
  bottom: 50px;
  width: 100%;
  text-align: center;
  color: #707072;
  letter-spacing: 2px;
}

.footer-bottom-icpbg {
  height: 15px;
  width: auto;
  vertical-align: middle;
}
</style>
